var templater = require("handlebars/runtime")["default"].template;module.exports = templater({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return "            "
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.singleSelection : depth0)) != null ? stack1.label : stack1), depth0))
    + "\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.selection : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.selection : depth0)) != null ? stack1.length : stack1), depth0))
    + " classes selected\n";
},"6":function(depth0,helpers,partials,data) {
    return "                Nothing selected\n";
},"8":function(depth0,helpers,partials,data) {
    var stack1;

  return "        <ul id=\"selection-class-list\">\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.selection : depth0),{"name":"each","hash":{},"fn":this.program(9, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "        </ul>\n\n        <h3>Most similar clusters:</h3>\n        <ul id=\"selection-similarity-list\">\n            <li class=\"header\">\n                <span class=\"percentage-indicator\"></span>\n                <span class=\"intersection-size\" title=\"Intersection of selection and cluster\"><span></span></span>\n                <span class=\"union-size\" title=\"Union of selection and cluster\"><span></span></span>\n                <span class=\"node-title\"></span>\n                <span class=\"cluster-size\" title=\"Size of cluster\"></span>\n            </li>\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.clusters : depth0),{"name":"each","hash":{},"fn":this.program(11, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "        </ul>\n";
},"9":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "                <li class=\"class-item\" data-node=\""
    + alias3(((helper = (helper = helpers.key || (depth0 != null ? depth0.key : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"key","hash":{},"data":data}) : helper)))
    + "\">\n                    "
    + alias3(((helper = (helper = helpers.trimmedLabel || (depth0 != null ? depth0.trimmedLabel : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"trimmedLabel","hash":{},"data":data}) : helper)))
    + "\n                </li>\n";
},"11":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":this.program(12, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                <li class=\"group-divider\"></li>\n";
},"12":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=this.lambda, alias2=this.escapeExpression, alias3=helpers.helperMissing, alias4="function";

  return "                    <li class=\"cluster-item\" data-node=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.node : depth0)) != null ? stack1.key : stack1), depth0))
    + "\">\n                        <span class=\"percentage-indicator\">\n                            <span class=\"tiny-barchart\">\n                                <span class=\"bar\" style=\"width: "
    + alias2(((helper = (helper = helpers.percent || (depth0 != null ? depth0.percent : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(depth0,{"name":"percent","hash":{},"data":data}) : helper)))
    + "%;\"></span>\n                                <span class=\"label\">"
    + alias2(((helper = (helper = helpers.percent || (depth0 != null ? depth0.percent : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(depth0,{"name":"percent","hash":{},"data":data}) : helper)))
    + "%</span>\n                            </span>\n                        </span>\n                        <span class=\"intersection-size\"><span>"
    + alias2(((helper = (helper = helpers.intersection || (depth0 != null ? depth0.intersection : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(depth0,{"name":"intersection","hash":{},"data":data}) : helper)))
    + "</span></span>\n                        <span class=\"union-size\"><span>"
    + alias2(((helper = (helper = helpers.totalCount || (depth0 != null ? depth0.totalCount : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(depth0,{"name":"totalCount","hash":{},"data":data}) : helper)))
    + "</span></span>\n                        <span class=\"node-title\">\n                            "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.clustering : depth0),{"name":"if","hash":{},"fn":this.program(13, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n                            "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.node : depth0)) != null ? stack1.label : stack1), depth0))
    + "\n                        </span>\n                        <span class=\"cluster-size\">\n                            "
    + alias2(((helper = (helper = helpers.clusterSize || (depth0 != null ? depth0.clusterSize : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(depth0,{"name":"clusterSize","hash":{},"data":data}) : helper)))
    + "\n                        </span>\n                    </li>\n";
},"13":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "<span style=\"font-weight: bold; color: "
    + alias3(((helper = (helper = helpers.clusteringColor || (depth0 != null ? depth0.clusteringColor : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"clusteringColor","hash":{},"data":data}) : helper)))
    + "\">"
    + alias3(((helper = (helper = helpers.clustering || (depth0 != null ? depth0.clustering : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"clustering","hash":{},"data":data}) : helper)))
    + "</span> &raquo; ";
},"15":function(depth0,helpers,partials,data) {
    return "        <p>Welcome to ClusterCompare,\n            a tool to visualize software modularizations\n            and compare them to multiple clustering results.\n        </p>\n\n        <ol>\n            <li>Select a software project to inspect at the top left.</li>\n            <li>Choose which clusterings you want to compare at the top right. You can rearrange them by dragging the headings.</li>\n            <li>Select classes by clicking on nodes. Ctrl-click to add classes to or remove them from the selection.</li>\n            <li>Details about the selection and similar clusters will be shown here.</li>\n        </ol>\n\n        <p>View <a href=\"https://clustercompare.jan-melcher.de/about\">Tutorial</a></p>\n        <p>Get SourceCode on <a href=\"https://github.com/clustercompare/clustercompare\">GitHub</a></p>\n\n        <p class=\"small\">&copy; Jan Melcher, 2016.</p>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"sidebar-body\">\n    <h3 id=\"selection-heading\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.singleSelection : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "    </h3>\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.selection : depth0),{"name":"if","hash":{},"fn":this.program(8, data, 0),"inverse":this.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});